<script>
import { onMounted, onUnmounted, watch, ref, reactive ,computed } from 'vue'
import { http } from '@/http'
import { setScrollTop, getScrollTop , yearMonth} from '@/utils'
import { needLoading, loading } from '@/reactive'
import useScrollData from '@/reactive/useScrollData'
import { ActionSheet , DatetimePicker } from 'vant'
import {useRoute} from 'vue-router'

export default {
  components: {
    [DatetimePicker.name]: DatetimePicker,
  },
  setup() {
    const route = useRoute()

    let userId = route.query.userId
    let shopList = ref([])
    let show = ref(false)
    let showDate = ref(false)
    let currentDate = ref(new Date())
    let shopId = ref('')
    let orderApiUrl = '/?cmd=com.xqxc.api.pigear.biz.account.BrokerService.listUserOrder'
    let columns = ref([])
    const customFieldName = {
      text: 'shopName',
    };
    let shopName = ref('全部')
    let dateTime = ref(new Date().getFullYear() + '/' + (new Date().getMonth()+1 < 10?('0'+(new Date().getMonth()+1)):new Date().getMonth()+1))
    let params = reactive({
      userId:userId,
      shopId:shopId,
      month: yearMonth(currentDate.value)
    })

    let { pageNum, tabType, getData, handleScroll,handleWatchData, dataList, dataListTemp, scrollElement, dataLength } = useScrollData()
    tabType.value = 'SHOP'
    
    //获取门店列表
    const shopDataList = () => {
      loading.value = true
      http.post('/?cmd=com.xqxc.api.pigear.biz.account.BrokerService.listUserShop',{userId: userId}).then(res => {
        if(res.result){
          loading.value = false
          shopList.value = res.result
          columns.value = shopList.value.map(item => {
            return {
              shopName:item.shopName,
              shopId:item.id
            }
          })
          columns.value.unshift({shopName:'全部',shopId:''})
        }
      })
    }

    const filterShopName = (val) => {
      if (!val) return
      if(val.length > 10){
        return val.slice(0,10) + '...'
      }
      return val
    }

    //初始化数据
    const initData = () => {
      dataList.value = []
      pageNum.value = 1
    }
    //选择门店
    const onShopConfirm = (item) => {
      show.value = false
      shopName.value = item.shopName
      shopId.value = item.shopId
      initData()
      getData(orderApiUrl, {...params})
    }
    //选择年月
    const onConfirm = (val) => {
      let time = new Date(val)
      dateTime.value = time.getFullYear() + '/' + (time.getMonth()+1 < 10?('0'+(time.getMonth()+1)):time.getMonth()+1)
      params.month = yearMonth(val)
      showDate.value = false
      initData()
      getData(orderApiUrl, {...params})
    }

    onMounted(() => {
      document.title = route.query.name
      shopDataList()
      getData(orderApiUrl, {...params})
      handleScroll(() => {
        getData(orderApiUrl, {
          ...params
        })
      })
    })

    return {
      dataList, 
      dataListTemp,
      scrollElement,
      loading,
      show,
      columns,
      customFieldName,
      onShopConfirm,
      shopName,
      dateTime,
      dataLength,
      showDate,
      minDate: new Date((new Date().getFullYear()-1),(new Date().getMonth())),
      maxDate: new Date(),
      currentDate,
      onConfirm,
      filterShopName
    }
  }

}
</script>
<template>
  <div>
    <div class="order-title">
      <div>
        <p @click="showDate = true">{{dateTime}} ({{dataLength}})<img src="../assets/images/order-btm.png" alt=""></p>
        <div class="title-all" @click="show = true">{{filterShopName(shopName)}}<img src="../assets/images/order-btm.png" alt=""></div>
      </div>
    </div>

    <div class="r-box" ref="scrollElement">
      <!-- <shopList v-if="tabType === 'SHOP'" :shopList="shopList" :loading="loading" /> -->
      <!-- <orderList v-if="tabType === 'ORDER'" :dataList="dataList" :dataListTemp="dataListTemp" :loading="loading" /> -->
      <ul class="order-ul" v-if="dataList.length">
        <li class="order-item list-item" v-for="(item,index) in dataList" :key="index">
          <div class="item-top">
            <img class="order-icon1" :src="item.orderSourceLogo" alt="">
            <p>{{item.orderSourceName}}</p>
            <h1 v-if="item.statusDesc !== '待发单'">{{item.deliveryId}}</h1>
            <h2>{{item.statusDesc}}</h2>
          </div>
          <div class="item-content">
            <div>
              <p><img class="img-time" src="../assets/images/order-time.png"  alt="">{{item.gmtCreate}}</p>
              <p class="item-position"><img class="img-position" src="../assets/images/order-shop.png" alt="">{{filterShopName(item.shopName)}}</p>
              <p><img class="img-shop" src="../assets/images/order-position.png" alt="">{{item.acceptedTransportName || '-'}}</p>
            </div>
            <h6 v-if="item.statusDesc === '已完成'">{{item.brokerCost.toFixed(3)}}</h6>
          </div>
        </li>
      </ul>
      <p class="no-data" v-if="loading === false && !dataList.length" style="text-align: center; font-size:.28rem">暂无数据 :(</p>
    </div>
    <van-popup v-model:show="show" round position="bottom">
      <van-picker :columns="columns" :columns-field-names="customFieldName" @cancel="show = false" @confirm="onShopConfirm" />
    </van-popup>
    <van-popup v-model:show="showDate" round position="bottom">
      <van-datetime-picker v-model="currentDate" type="year-month" title="选择年月" :min-date="minDate" :max-date="maxDate" @cancel="showDate = false" @confirm="onConfirm" />
    </van-popup>
  </div>

</template>

<style lang="less" scoped>
.r-box{
    padding: 0;
    background: none;
  }
  .order-title{
    height: .8rem;
    font-size: .28rem;
    >div{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: fixed;
      top: .88rem;
      padding: 13px 20px;
      box-sizing: border-box;
      background: #f4f5f9;
      .title-all{
        display: flex;
        align-items: center;
      }
      img{
        width: .22rem;
        height: .12rem;
        margin-left: .1rem;
      }
    }
  }

  .list-item{
    margin-bottom: 10px;
    background: #fff;
    border-radius: 6px;
    font-size: .28rem;
    color: #333333;
  }
  .order-item{
    padding: .31rem;
    >div{
      display: flex;
      align-items: center;
      &.item-top{
        margin-bottom: .34rem;
        border-bottom: 1px solid rgba(242, 242, 242, 1);
        padding-bottom: .23rem;
        p{
          margin-right: 0.2rem;
          border-right: 1px solid #333333;
          padding-right: 0.2rem;
        }
        h2{
          margin-left: auto;
        }
        .order-icon1{
          width: .48rem;
          height: .48rem;
          margin-right: .2rem;
          border-radius: 50%;
        }
      }
      &.item-content{
        >div{
          padding-left: .1rem;
        }
        p{
          display: flex;
          align-items: center;
          img{
            margin-right: .45rem;
          }
        }
        .item-position{
          margin: .15rem 0;
        }
        .img-time{
          width: .3rem;
          height: .3rem;
        }
        .img-position{
          width: .28rem;
          height: .24rem;
        }
        .img-shop{
          width: .29rem;
          height: .23rem;
        }
        h6{
          margin-left: auto;
          border-left: 1px solid #ccc;
          padding: 15px 20px 15px 30px;
          color: #D9001B;
        }
      }
    }
  }
  .no-data{
    padding: 15px;
    background: #fff;
    border-radius: 6px;
  }
</style>